import React, { useState, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import Main from './Main';
import LanguageButton from '../../components/LanguageButton';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
// import QrReader from 'react-qr-reader';

import { LOCK_INSTITUTION } from '../../constants';
import ConfigService from '../../services/ConfigService';
import safeJSONParse from '../../util/safeJSONParse';


function RegistrationPage(props){
    const { t } = useTranslation('register-index');
    const urlData = useMemo(() => queryString.parse(window.location.hash), [window.location.hash]);
    const { host: urlHost, branch: urlBranch, ticket: urlTicket } = urlData;
    const { branch: matchBranch, queue } = props.match.params;

    const [config, setConfig] = useState(safeJSONParse(localStorage.getItem("config")));
    const [loading, setLoading] = useState(false);
    const [host, setHost] = useState(urlHost || LOCK_INSTITUTION || null);
    const [branchId, setBranchId] = useState((matchBranch | 0) || urlBranch || null);
    const [error, setError] = useState(null);

    const { branch } = config || {};
    const country_codes = ['PR', 'US']

    useEffect(() => {
        try {
            fetch('https://geolocation-db.com/json/')
                .then(res => res.json())
                .then(json => {
                    console.log(json)
                    if(!country_codes.includes(json.country_code)) {
                        console.log("This country is not allowed");
                        props.history.push('/redirect');
                    }
                })
        }
        catch(err) {
            console.log("Error retrieving ip info");
            props.history.push('/redirect');
        }
        if(branchId && host){
            onUpdateBranch(host, branchId);
        }
    }, []);

    function onUpdateBranch(host, branchId){
        setLoading(true);
        setError(null);

        ConfigService.fetchConfigFor({
            host,
            branch: branchId,
            queue
        }).then((config) => {
            localStorage.setItem("config", JSON.stringify(config));

            setConfig(config)
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            setError("branch");
        });
    }

    function handleChange(event) {
        ({ host: setHost, branchId: setBranchId })[event.target.name](event.target.value);
    }

    return (<div>
        <LanguageButton />
        {
        loading ? (
            <section className="hero is-fullheight vq-intro-page">
                <div className="hero-body">
                    <div className="container has-text-centered">
                        <div className="lds-dual-ring"></div>
                    </div>
                </div>
            </section>
        ) : (branch) ? (
            <Main host={host} branchId={branchId} config={config} queue={queue}/>
        ):(
            <section className="hero is-fullheight vq-intro-page">
                <div className="hero-body">
                    <div className="container has-text-centered">
                        <div className="columns">
                            <div className="column is-6 is-offset-3">
                                <figure className="image">
                                    <img style={{height: 85}} src="VirtualQ.svg" alt="Logo"/>
                                </figure>
                            </div>
                        </div>
                        <ManualHostBranchRegistrationForm
                            hideHost={!!LOCK_INSTITUTION}
                            host={host}
                            branchId={branchId}
                            onChange={handleChange}
                            onSubmit={() => onUpdateBranch(host, branchId)}
                        />
                        { error &&
                            <div className="columns">
                                <div className="column is-12 is-danger">
                                    <button className="button is-danger is-fullwidth">{t('error-' + error)}</button>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </section>
        )
    }</div>);
}


function ManualHostBranchRegistrationForm({
    host,
    hideHost,
    branchId,
    onChange,
    onSubmit,
}){
    return (<div>
        <div className="columns is-centered">
            {!hideHost &&<div className="column is-4 is-offset-2">
                <input className="input is-large register-input" name="host" type="text" placeholder="Host *" value={host} onChange={onChange}/>
            </div>}
            <div className="column is-4">
                <input className="input is-large register-input" name="branchId" type="text" placeholder="Branch ID *" value={branchId || ''} onChange={onChange}/>
            </div>
        </div>
        <div className="columns">
            <div className="column is-6 is-offset-3">
                <button className="button is-large square-button" onClick={onSubmit}>Submit</button>
            </div>
        </div>
    </div>)
}


export default withRouter(RegistrationPage);
