import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import AproxMinutes from './AproxMinutes';
import CurrentTurn from './CurrentTurn';
import WaitingList from './WaitingList';
import WaitingTurn from './WaitingTurn';
import Loading from '../../../components/Loading';
import Logo from '../../../components/Logo';
import ConfigService from '../../../services/ConfigService';
import QueueService from '../../../services/QueueService';
import { LOCK_INSTITUTION } from '../../../constants';

function Current(props) {
    const { t } = useTranslation('display');
    const host = LOCK_INSTITUTION
    const {
        match: { params: {
            branch, ticket: propTicket, queue
        } },
    } = props

    const [data, setData] = useState();
    const [config, setConfig] = useState();
    const {
        theme,
        branch: branchName,
        queue: queueName
    } = config || {};
    const [ticketEdit, setTicketEdit] = useState(propTicket);
    const [ticket, setTicket] = useState(propTicket);

    const [dataLoading, setDataLoading] = useState();
    const [configLoading, setConfigLoading] = useState();
    const [timeoutHandle, setTimeoutHandle] = useState();
    const loading = dataLoading || configLoading;


    async function loadData(hideLoaders) {
        clearTimeout(timeoutHandle);

        try {
            if (!hideLoaders) {
                setDataLoading(true);
            }
            const data = await QueueService.fetchWaitingDataFor({
                host,
                queue,
                branch,
                ticket
            });
            setData(data);
        } catch(e) {}
        setDataLoading(false);

        setTimeoutHandle(setTimeout(() => loadData(true), 5000));
    }

    async function loadConfig() {
        try {
            setConfigLoading(true);
            setConfig(await ConfigService.fetchConfigFor({host, branch, queue}));
        } catch(e) {}
        setConfigLoading(false);
    }

    useEffect(() => {
        loadData();
        loadConfig();
        return () => {
            clearTimeout(timeoutHandle);
        }
    }, []);


    function handleSubmitClick(e) {
        e.preventDefault();
        setTicket(ticketEdit);
        loadData();
    }

    function onChange(e) {
        setTicketEdit(e.target.value);
    }

    const {
        avgTimeForPerson,
        avgWaitTime,
        current,
        ticketPerson,
        waiting
    } = data || {};

    const {
        css,
        background: themeBackground,
        logo: themeLogo,
        calendar: themeCalendar,
        productcolor: themeProductColor,
        ticket: themeTicket,
    } = theme || {};

    return (
        <div>
            {css && <style>{css}</style>}
            {loading ? (
                <Loading background={themeBackground} />
            ) : (
                <div
                    className="hero is-fullheight has-text-centered display-page"
                    style={{
                        backgroundImage: `url("${themeBackground}")`,
                        backgroundSize: 'cover',
                        color: '#FFF'
                    }}
                >
                    <div className="container">
                        <Logo logo={themeLogo} />
                        <CurrentTurn
                            calendar={themeCalendar}
                            location={branchName}
                            queue={queueName}
                            currentTurn={current}
                            productColor={themeProductColor}
                        />
                        {ticketEdit && ticket ? (
                            <WaitingTurn
                                handleSubmitClick={handleSubmitClick}
                                onChange={onChange}
                                ticket={themeTicket}
                                name={ticketPerson ? ticketPerson.name : t('loading')}
                                personTurn={ticketEdit}
                                productColor={themeProductColor}
                            />
                        ) : (
                            <WaitingList
                                handleSubmitClick={handleSubmitClick}
                                onChange={onChange}
                                ticket={ticketEdit}
                                waiting={waiting}
                                productColor={themeProductColor}
                            />
                        )}
                        <AproxMinutes
                            avgWaitTime={avgWaitTime}
                            ticket={ticketEdit}
                            avgTimeForPerson={avgTimeForPerson}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Current;
