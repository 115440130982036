import Ath from "../../assets/icons/icons-ath.svg"
import Check from "../../assets/icons/icons-checking-accounts.svg"
import Claim from "../../assets/icons/icons-claims.svg"
import Credit from "../../assets/icons/icons-credit-cards.svg"
import Ira from "../../assets/icons/icons-iras-cds.svg"
import Loan from "../../assets/icons/icons-loans.svg"
import Transfer from "../../assets/icons/icons-wire-transfers.svg"


const icons = {
    ath: Ath,
    check: Check,
    claim: Claim,
    credit: Credit,
    ira: Ira,
    loan: Loan,
    transfer: Transfer
}


export default icons;
