import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import LanguageButton from '../../components/LanguageButton';
import Current from './Current';
import Form from './Form';


function DisplayPage(props){

    console.log(props.location)
    return (
        <div>
            <LanguageButton />
            <Switch>
                <Route path="/" exact component={Form} />
                <Route path="/:branch/:queue?/:ticket?" exact component={Current} />
            </Switch>
        </div>
    );
}

export default withRouter(DisplayPage);
