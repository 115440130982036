import Start from './Start'
import Register from './Register'
import Product from './Product'
import Task from './Task'
import Turn from './Turn'
import Thanks from './Thanks'

const slides = [
    {
        component: Start,
        footer: false,
        back: false,
        language: true
    },
    {
        component: Register,
        footer: false,
        back: false
    },
    {
        component: Product,
        footer: false,
        back: true
    },
    {
        component: Task,
        footer: false,
        back: true
    },
    {
        component: Turn,
        footer: false,
        back: false
    },
    {
        component: Thanks,
        footer: false,
        back: false
    }
];


export default slides;
