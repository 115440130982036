import React from 'react';
import { useTranslation } from 'react-i18next';

const WaitingTurn = ({ name, personTurn, productColor, ticket }) => {
    const { t } = useTranslation('display');
    return (
    <div
        className="card"
        style={{
            margin: '15px',
            borderRadius: '10px',
            padding: '0.5rem',
            background: productColor
        }}
    >
        <p className="text-your-turn is-size-4">
            {name && (<span style={{ textTransform: 'capitalize' }}>{name},</span>)}
            {t('your-current-turn')}
        </p>
        <div className="image-container">
            <figure className="image is-128x128">
                <img src={ticket} alt="ticket" />
                <p className="ticket-text">
                    <span className="text-turn-ticket">{personTurn}</span>
                </p>
            </figure>
        </div>
        <p className="text-notification-box is-size-6" style={{ lineHeight: '15px' }}>
            {t('sms-notification')}
        </p>
    </div>
    )
};

export default WaitingTurn;
