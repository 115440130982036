import hostUrl from '../util/hostUrl';


const ConfigService = {
    async fetchConfigFor({
        host,
        branch,
        queue,
    }){
        const response = await fetch(`${hostUrl({ host })}/api/branch/${branch | 0}/queue/${queue || 'default'}/config/`);
        const config = await response.json();

        const bgsplit = (config.theme.background || '').split('\n');
        config.theme.background = bgsplit.shift();
        config.theme.css = bgsplit.join('\n');
        config.maxPartyAmount = config.maxPartyAmount ? config.maxPartyAmount : 1; 
        return config;
    }
}


export default ConfigService
