import React, { useState } from 'react';
import MaskedInput from 'react-text-mask'
import { useTranslation } from 'react-i18next';
import partyBG from '../../../assets/partyImgBlack.png';



export default function Turn({
    config,
    ticket,
    phone: propPhone,
    onUpdateState,
    partyAmount
}) {
    const { t } = useTranslation('register-turn');
    const [state, setState] = useState({
        phone: propPhone || ""
    });
    const { phone } = state;
    const [error, setError] = useState();

    function handleChange(event) {
        setState({
            [event.target.name]: event.target.value
        });
    }

    function onSubmit(addPhone){
        if(addPhone){
            if (phone){
                if (phone.match(/\(\d{3}\)\ \d{3}-\d{4}/)){
                    onUpdateState({
                        finished: true,
                        phone: phone
                    })
                }
                else {
                    setError("cell")
                }
            }
            else {
                onUpdateState({finished: true})
            }
        }
        else {
            onUpdateState({finished: true})
        }

    }

    return (
        <div className="container has-text-centered">
            <div className="columns is-vcentered"  style={{ paddingBottom: 30}}>
                <div className="column is-6">
                    <h3 className="title is-2 text-notification-box" style={{textAlign: "left", padding: 5}}> {t('title-thanks')} </h3>
                    <h3 className="title is-5 text-notification-box" style={{textAlign: "right", padding: 5}}> {t('title-yournumber')} </h3>
                </div>
                <div className="column is-6 ticket" style={{
                    backgroundImage: `url(${config.theme.ticket})`
                }}>
                    <p className="ticketNumber">{ticket}</p>
                    <div className="party">
                        <p className="partyAmount">{partyAmount}</p>
                    </div>
                </div>
            </div>
            <div className="columns is-vcentered">
                <div className="column is-5">
                    <figure className="image">
                        <img src={config.theme.handPhone}/>
                    </figure>
                </div>
                {
                    phone ? (
                        <div className="column is-6 is-offset-1">
                            <p className="subtitle text-notification-box" style={{textAlign: "left", padding: 0}}>
                                {t('text-message')}
                            </p>
                            <div className="columns is-multiline">
                                <div className="column is-12">
                                </div>
                            </div>
                        </div>
                        ) : (
                        <div className="column is-6 is-offset-1">
                            <p className="title is-4 text-notification-box" style={{textAlign: "left", padding: 0}}>
                                {t('text-prompt1')}
                            </p>
                            <p className="subtitle text-notification-box" style={{textAlign: "left", padding: 0}}>
                                {t('text-prompt2')}
                            </p>
                            <div className="columns is-multiline">
                                <div className="column is-12">
                                    <MaskedInput
                                        className="input is-large register-input"
                                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                        name="phone"
                                        type="tel"
                                        guide={true}
                                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                        placeholder={t('placeholder-cell')}
                                        value={phone} onChange={handleChange}
                                    />
                                </div>
                                <div className="column is-5 is-offset-1">
                                    <button className="button is-default square-button" onClick={() => onSubmit(false)}>{t('btn-no')}</button>
                                </div>
                            { phone &&
                                <div className="column is-4">
                                    <button className="button is-default square-button" onClick={() => onSubmit(true)}>{t('btn-submit')}</button>
                                </div>
                            }
                            </div>
                            { error &&
                                <div className="columns">
                                    <div className="column is-12 is-danger">
                                        <button className="button is-danger is-fullwidth">{t('error-' + error)}</button>
                                    </div>
                                </div>
                            }
                        </div>
                        )
                }

            </div>
        </div>
    );
}
