import React from 'react';
import logo from '../../assets/icons/icon.png';
import googlePlay from '../../assets/icons/google-play.png';
import { useTranslation } from 'react-i18next';
import LanguageButton from '../../components/LanguageButton';

function RedirectPage() {
    const { t } = useTranslation('redirect-page');

    return (
        <section className="hero is-fullheight vq-intro-page">
            <LanguageButton />
            <div className="hero-body">
                <div className="container has-text-centered">
                    <div className="container">
                        <div className="columns">
                            <div className="column is-12">
                                <h3 className="title" style={{ textTransform: 'uppercase'}}>{t('title')}</h3>
                            </div>
                            <div className="column is-6 is-offset-3">
                                <figure>
                                    <img height="100" width="110" src={logo} alt="Logo"/>
                                    <figcaption style={{ color: '#000', textTransform: 'capitalize' }}>{t('subtitle')}</figcaption>
                                </figure>
                            </div>
                            <div className="column is-12 is-6">
                                <a href="https://apps.apple.com/us/app/virtual-q-solutions/id1508577768?mt=8" style={{ display: "inline-block", overflow: "hidden", background: "url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2020-04-22&kind=iossoftware&bubble=ios_apps) no-repeat", width: "135px", height: "40px"}}></a>
                            </div>
                            <div className="column is-12 is-6">
                                <a href="https://play.google.com/store/apps/details?id=com.esperaseguro.app" target="_blank" rel="external">
                                    <img src={googlePlay} height="60" width="155" alt="google play" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default RedirectPage;