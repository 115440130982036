import React, { useState, useMemo, useEffect } from 'react';
import MaskedInput from 'react-text-mask'
import { useTranslation } from 'react-i18next';

import safeJSONParse from '../../../util/safeJSONParse';

// required_field -> partyAmount -> error

function Register({
    introSkipped,
    onUpdateState,
    config: {
        branch,
        queue,
        required_fields: prop_required_fields,
        maxPartyAmount
    },
}) {
    const { t } = useTranslation('register-form');
    const required_fields = useMemo(() =>
        (safeJSONParse(prop_required_fields) || ["name", "phone", "lastname", "partyAmount"]).reduce((_, f) => {
            _[f] = 1;
            return _;
        }, {}), [prop_required_fields]
    );

    const [state, setState] = useState({
        name: "",
        lastname: "",
        phone: "",
        partyAmount: ""
    });
    const { name, lastname, phone, partyAmount } = state;
    const [error, setError] = useState();

    const changeChecks = {
        partyAmount: v => Math.max(1, Math.min(v, maxPartyAmount))
    }

    function handleChange({ target: { name, value }}) {
        const checker = changeChecks[name];
        value = checker ? checker(value) : value;
        setState({
            ...state,
            [name]: value,
        });
        setError();
    }

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(position => {
            localStorage.setItem('latitude', position.coords.latitude);
            localStorage.setItem('longitude', position.coords.longitude);
        }, (error) => {
            console.log("Error code = " + error.code + " - " + error.message)
        })
    }, []);

    function onSubmit(){
        if (Object.entries(required_fields).reduce((err, [field, required]) => {
            if (!err && required && !state[field]) {
                setError(field);
                return field;
            }
            return err;
        }, null)){
            return;
        }

        if (phone && phone.match(/\(\d{3}\)\ \d{3}-\d{4}/)){
            onUpdateState({
                name: name || '---',
                lastname: lastname || '---',
                phone,
                partyAmount,
            })
        } else {
            setError("cell");
        }

    }

    return (
        <div className="column is-10 is-offset-1">
            {introSkipped && (<>
                <div className="columns">
                    <div className="column is-12">
                        <h3 className="title text-instructions-title">
                            {branch}
                            {queue && (<div className="subtitle">{queue}</div>)}
                        </h3>
                    </div>
                </div>
            </>)}
            <h3 className="subtitle text-instructions-title page-title">{t('title')}</h3>
            <div className="columns is-12">
                <div className="column is-6">
                    <input className={`input is-large register-input ${error === 'name' ? 'is-danger': ''}`}
                        name="name"
                        type="text"
                        placeholder={t("placeholder-name") + (required_fields.name ? ' *' : '')}
                        value={name}
                        onChange={handleChange}
                    />
                </div>
                <div className="column is-6">
                    <input className={`input is-large register-input ${error === 'lastname' ? 'is-danger': ''}`}
                        name="lastname"
                        type="text"
                        placeholder={t("placeholder-lastname") + (required_fields.lastname ? ' *' : '')}
                        value={lastname}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="columns">
                <div className="column is-6">
                    <MaskedInput
                        className={`input is-large register-input ${error === 'cell' ? 'is-danger': ''}`}
                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        name="phone"
                        type="tel"
                        guide={true}
                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        placeholder={t("placeholder-cell") + (required_fields.phone ? ' *' : '')}
                        value={phone} onChange={handleChange}
                    />
                </div>
                <div className="column is-6">
                    <input
                        className={`input is-large register-input ${error === 'partyAmount' ? 'is-danger': ''}`}
                        name="partyAmount"
                        type="number"
                        guide={true}
                        min="1" max={maxPartyAmount}
                        placeholder={t("placeholder-partyAmount")}
                        value={partyAmount} onChange={handleChange}
                    />
                </div>
            </div>
            <div className="columns">
                <div className="column is-12">
                    <p className="declaimer">
                        {t('sms-disclaimer')}
                    </p>
                </div>
            </div>

            <div className="columns">
                <div className="column is-6 is-offset-3">
                    <button className="button is-large square-button" onClick={onSubmit}>{t('request-ticket')}</button>
                </div>
            </div>


            { error &&
                <div className="columns">
                    <div className="column is-12 is-danger">
                        <button className="button is-danger is-fullwidth">{t('error-' + error)}</button>
                    </div>
                </div>
            }
        </div>
    )
}

export default Register;
