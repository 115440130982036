import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';


export default function Product({
    config,
    onUpdateState,
}) {
    const { t } = useTranslation('register-product');
    console.log("products", config.products);

    return (
        <div className="column is-12">
            <div className="columns">
                <div className="column">
                    <h1 className="title text-transaction-type page-title">{t('title')}</h1>
                </div>
            </div>
            <br/>
            <div className="columns is-multiline">

            {
                Object.keys(config.products).map((key, i) => {
                    return (
                        <div className="column is-3" key={i}>
                            <div className="card borderless" style={{ backgroundColor: config.theme.productColor }}>
                                <div className="card-content" onClick={() => onUpdateState({product: config.products[key] })}>
                                    <div className="content text-placeholder" style={{ fontWeight: "bold"}}>
                                        <figure className="image product-image">
                                            <img src={config.products[key].icon} alt="Placeholder"/>
                                        </figure>
                                        <p>{ key }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }

            </div>
        </div>
    )
};
