import hostUrl from '../util/hostUrl';


const QueueService = {
    async fetchWaitingDataFor({
        host,
        branch,
        queue,
        ticket
    }){
        const urlData = ticket
        ? `${hostUrl({ host })}/api/branch/${branch | 0}/queue/${queue || 'default'}/waiting/${ticket}/`
        : `${hostUrl({ host })}/api/branch/${branch | 0}/queue/${queue || 'default'}/waiting/`;

        return (await fetch(urlData)).json();
    },

    async registerInQueue({
        host,
        branch,
        queue,
        name,
        lastname,
        partyAmount,
        phone,
        purpose,
        latitude,
        longitude
    }){
        const response = await (await fetch(`${hostUrl({ host })}/api/branch/${branch}/queue/${queue || 'default'}/entry/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name, lastname,
                phone: phone ? "+1" + phone.replace(/\(/g, "").replace(/\) /g, "").replace(/-/g, ""): "",
                purpose,
                latitude,
                longitude,
                partyAmount
            }),
        })).json();

        if (response.error) {
            const err = new Error(response.error);
            err.key = response.key;
            throw err;
        }

        return response;
    },
}

export default QueueService
