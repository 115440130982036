import React from 'react';
import { Redirect } from 'react-router-dom';

function ClearLocalStoragePage(props){
    localStorage.clear()

    return (<Redirect to="/register" />);
}


export default ClearLocalStoragePage;
