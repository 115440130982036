import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import RegistrationPage from './pages/RegistrationPage';
import DisplayPage from './pages/DisplayPage';
import RedirectPage from './pages/RedirectPage';
import ClearLocalStoragePage from './pages/ClearLocalStoragePage';


const Routes = () => (
    <Router>
        <Switch>
            <Route path="/redirect" component={RedirectPage} />
            <Route path="/register/clear" component={ClearLocalStoragePage} />
            <Route path="/register/:branch?/:queue?" exact component={RegistrationPage} />
            <Route path="/" component={DisplayPage} />
        </Switch>
    </Router>
);

export default Routes;
