
/** Parses a JSON string, returning a given defaultValue if unparseable.
 * @params {String} json - The json string to parse
 * @params {ANY} defaultValue - The default value to return if json is unparseable.
 */
function safeJSONParse(json, defaultValue){
    try {
        return JSON.parse(json);
    } catch(e){
        return defaultValue;
    }
}


export default safeJSONParse;
