import React from 'react';

const Logo = ({ logo }) => (
    <div
        style={{ marginTop: '10px', height: '2.5rem' }}
        className="is-horizontal-center is-flex"
    >
        <figure className="image is-128x128">
            <img src={logo} alt="logo" />
        </figure>
    </div>
);

export default Logo;
