import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Routes from './Router'
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import { SENTRY_KEY } from './constants';
import './i18n';


if (SENTRY_KEY) {
    Sentry.init({dsn: SENTRY_KEY});
}

ReactDOM.render(<Routes />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
