import React, { Component } from 'react';
import QRCode from 'qrcode.react';
import queryString from 'query-string';
import { LOCK_INSTITUTION } from '../../../constants';
import { useTranslation } from 'react-i18next';

const urlData = queryString.parse(window.location.hash)

function Start({
    config,
    host, branchId,
    onUpdateState,
}) {
    const { t, i18n } = useTranslation('register-start');
    const { href: qrhref } = window.location;

    return (
      <div className="column is-10 is-offset-1">
        <div className="columns">
          <div className="column is-6 is-offset-3">
            <figure>
              <img
                style={{ width: '80%' }}
                src={config.theme.logo}
              />
            </figure>
          </div>
        </div>
        <div className="columns">
          <div className="column is-12">
            <h3 className="title text-instructions-title">
              {t('welcome-text')}<br />
              {config.branch} <br />
              {config.queue} <br />
            </h3>
            <br />
            <h3 className="subtitle text-instructions-subtitle">
              {t('instructions')}
            </h3>
          </div>
        </div>
        <div className="columns">
          <div className="column is-4 is-offset-4">
            <button
              className="button is-default is-outlined square-button"
              onClick={() => onUpdateState({ started: true })}
            >
              {t('start')}
            </button>
          </div>
        </div>
        {urlData.device?
          (
            <div>
            </div>
          ):(
            <div className="columns">
              <div className="column is-4 is-offset-4">
                <QRCode value={qrhref} />
              </div>
            </div>
          )
        }
      </div>
    );
}

export default Start;
