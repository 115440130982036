import React from 'react';
import { useTranslation } from 'react-i18next';

const WaitingList = ({
    handleSubmitClick,
    onChange,
    productColor,
    ticket,
    waiting
}) => {
    const { t } = useTranslation('display');
    return (
    <div>
        <div
            className="card"
            style={{
                margin: '15px',
                borderRadius: '10px',
                padding: '0.5rem',
                background: productColor
            }}
        >
            <p className="text-waiting-count is-size-5">
                {t('people-waiting')}
            </p>
            <p style={{ fontFamily: 'arial' }} className="text-waiting-count is-size-3">
                {waiting}
            </p>
        </div>
        <div className="column">
            <div className="column">
                <input
                    className="input register-input"
                    name="ticket"
                    type="number"
                    pattern="\d*"
                    placeholder={t('input-number')}
                    value={ticket}
                    onChange={onChange}
                />
            </div>
            <div className="column">
                <button
                    className="button is-large square-button"
                    onClick={handleSubmitClick}
                >
                    {t('submit')}
                </button>
            </div>
        </div>
    </div>
    )
};

export default WaitingList;
