import icons from './icons';

const DEFAULT_CONFIG = {
    theme:{
        background: "https://s3.amazonaws.com/akcelita-artsense/images/background/pattern-gray.jpg",
        logo: "https://s3.amazonaws.com/akcelita-artsense/images/logo-artsense-small.svg",
        productColor: "#AAA",
        taskColor: "#AAA",
        ticket: "https://s3.amazonaws.com/akcelita-artsense/images/icons/ticket-blue.svg",
        handPhone: "https://s3.amazonaws.com/akcelita-artsense/images/icons/phone-blue.svg"
    },
    branch: "Chicago",
    products: {
        "Tarjeta ATH" :{
            id: 5,
            name: "Tarjeta ATH",
            icon: icons.ath,
            tasks: {
                "Asignar PIN" : { id: 4 },
                "Solicitar o activar tarjeta ATH": { id: 3 },
                "Cancelar tarjeta" : { id: 2}
            }
        },
        "Tarjeta de Credito" :{
            id: 5,
            name: "Tarjeta de Credito",
            icon: icons.credit,
            tasks: {
                "Solicitar tarjeta de crédito" : { id: 4 },
                "Perdí mi tarjeta de crédito": { id: 3 },
                "Información sobre opciones de tarjetas" : { id: 2}
            }
        },
        "Productos" :{
            id: 5,
            name: "Productos",
            icon: icons.ira,
            tasks: {
                "Hipoteca" : { id: 4 },
                "Otros": { id: 3 },
            }
        },
        "Prestamos" :{
            id: 5,
            name: "Prestamos",
            icon: icons.loan,
            tasks: {
                "Préstamo personal" : { id: 4 },
                "Préstamo de negocio": { id: 3 }
            }
        },
        "Reclamaciones" :{
            id: 5,
            name: "Reclamaciones",
            icon: icons.claim,
            tasks: {
                "Reclamación nueva" : { id: 4 },
                "Reclamación existente": { id: 3 }
            }
        },
        "Transferencias" :{
            id: 5,
            name: "Transferencias",
            icon: icons.transfer,
            tasks: {
                "Realizar transferencia" : { id: 4 },
                "Contratar transferencia": { id: 3 }
            }
        },
        "Cuenta de Cheques" :{
            id: 5,
            name: "Cuenta de Cheques",
            icon: icons.check,
            tasks: {
                "Solicitar cheques" : { id: 4 },
                "Abrir cuenta de cheque": { id: 3 }
            }
        },
        "IRAs + CDs" :{
            id: 5,
            name: "IRAs + CDs",
            icon: icons.ira,
            tasks: {
                "Información sobre cuentas IRA y/o CD" : { id: 4 },
                "Abrir cuenta IRA": { id: 3 },
                "Abrir CD" : { id: 2 }
            }
        }
    }
}

export default DEFAULT_CONFIG;
