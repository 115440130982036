import React, { useState, useEffect, useMemo } from 'react';

import icons from './icons';
import DEFAULT_CONFIG from './default_config';
import slides from './slides';
import QueueService from '../../services/QueueService';
import { useTranslation } from 'react-i18next';

import { LOCK_STATE } from '../../constants';

function Main(props) {
    const { t, i18n: { language: lng } } = useTranslation('register-main');

    const initialState = useMemo(() => ({
        started: null,
        name: null,
        lastname: null,
        phone: null,
        product: null,
        task: null,
        ticket: null,
        finished: null,
        partyAmount: null,
        ...LOCK_STATE,
    }), [LOCK_STATE]);

    console.log(initialState);

    const [state, setState] = useState(initialState);
    const [error, setError] = useState();

    const {
        name,
        lastname,
        phone,
        product,
        task,
        started,
        ticket,
        finished,
        submit,
    } = state;

    const [timeout, setTimeoutState] = useState(null);
    const [loading, setLoading] = useState(false);

    const { config: propConfig, host, branchId, queue } = props;

    const lsConfig = useMemo(() => (propConfig ? {
        ...propConfig,
        products: Object.entries(propConfig.products || {}).reduce((_, [task, value]) => {
            _[task] = {
                ...value,
                icon: icons[value.icon],
            }
            return _;
        }, {})
    } : {}), [propConfig]);
    const [config, setConfig] = useState(lsConfig || DEFAULT_CONFIG);

    function onUpdateState(update, submit=false){
        if (timeout) {
            clearTimeout(timeout);
        }

        setTimeoutState(setTimeout(clear, 600000));

        const newState = {...state, ...update};

        if (newState.name && newState.lastname && newState.phone && newState.task){
            newState.submit = true;
        }

        setState(newState);
    }

    function clear(){
        console.log("Clearing...")
        if (timeout){
            clearTimeout(timeout);
        }

        setState(initialState);
        setError();
        setTimeout(null);
        setLoading(false);
    }

    async function registerTicket(){
        setLoading(true);
        const lat = parseFloat(localStorage.getItem('latitude'));
        const lon = parseFloat(localStorage.getItem('longitude'));
        try {
            console.log("<><><><> Register <><><><>",state)
            const entry = await QueueService.registerInQueue({
                host,
                queue,
                lng,
                branch: branchId,
                name: state.name,
                lastname: state.lastname,
                partyAmount: state.partyAmount || 1,
                phone: state.phone,
                purpose: "task" in state ? state.task : 1,
                latitude: lat,
                longitude: lon
            });

            setLoading(false);

            setState({
                ...state,
                ticket: entry.number,
            });
        } catch(err) {
            setLoading(false);
            setError(err.key || 'submit');
        }
    }

    const slide = slides[useMemo(() => {
        if (!started){
            return 0;
        } else if (started && (!name && !lastname)){
            return 1;
        } else if (name && lastname && !product){
            return 2;
        } else if (product && !task){
            return 3;
        } else if (task && !finished){
            return 4;
        } else if(finished){
            return 5;
        } else {
            return 0;
        }
    }, [
        started, name, lastname, product, task, finished
    ])];

    useEffect(() => {
        if(lsConfig && lsConfig != config){
            setConfig(lsConfig);
        }
    }, [lsConfig]);

    useEffect(() => {
        if (submit){
            registerTicket();
        }
    }, [submit]);

    const SlideName = slide.component
    const { theme } = config
    const { css } = theme;

    return (
        <HeroLayout theme={config.theme} onBack={slide.back && clear} hasFooter={slide.footer}>
            {(loading) ? (
                <div className="lds-dual-ring"></div>
            ) : error ? (
                <div className="message is-danger">
                    <div className="message-header">
                        {t('error')}
                    </div>
                    <div className="message-body">
                        <p>{t('error-' + error)}</p>
                        <button className="button" onClick={clear}>{t('ok')}</button>
                    </div>
                </div>

            ) : (
                <SlideName
                    onUpdateState={onUpdateState}
                    host={host}
                    introSkipped={!!initialState.started}
                    branchId={branchId}
                    config={config}
                    phone={state.phone}
                    product={state.product}
                    ticket={state.ticket}
                    name={state.name}
                    reset={clear}
                    partyAmount={state.partyAmount}
                />
            )}
        </HeroLayout>
    );
}


function HeroLayout({
    theme: {css, background, logo},
    onBack,
    children,
    hasFooter,
}){
    const { t } = useTranslation('layout-hero');
    return (
        <section className="hero is-fullheight register-page" style={{
            backgroundImage: "url("+ background +")",
            backgroundSize: "cover",
        }}>
        {css && <style>{css}</style>}
            <div className={`columns is-mobile is-centered top-back main ${onBack ? 'has-back' : ''}`} >
                <div className="column is-3 is-flex is-horizontal-center">
                    { onBack &&
                        <button className="button is-default is-small square-button" onClick={onBack}> {t('go-back')} </button>
                    }
                </div>
                <div className="column is-6 is-flex is-horizontal-center">
                    <figure className="image">
                        <img style={{height: 35}} src={logo}/>
                    </figure>
                </div>
                <div className="column is-3 is-flex is-horizontal-center">
                </div>
            </div>


            <div className="hero-body main">
                <div className="container has-text-centered">
                    { children }
                </div>
            </div>




        { hasFooter &&
            <footer className="footer bottom">
                <div className="columns">
                    <div className="column is-3">
                        <figure className="image is-48x48">
                            <img className="is-rounded" src="https://bulma.io/images/placeholders/128x128.png"/>
                        </figure>
                    </div>
                </div>
            </footer>
        }
        </section>
    );
}

export default Main;
