import React, { useState, useEffect, useMemo } from 'react';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

import { LOCK_INSTITUTION } from '../../../constants';

export default function Form({
    history
}) {
    const { t } = useTranslation('display');
    const urlData = useMemo(() => queryString.parse(window.location.hash), [window.location.hash]);
    const { branch: urlBranch, ticket: urlTicket, queue:urlQueue } = urlData;

    const [state, setState] = useState({
        branch: urlBranch || '',
        queue: urlQueue || '',
        ticket: urlTicket || '',
    })
    const {
        branch, ticket, queue
    } = state

    function onChange({target: {name, value}}) {
        setState({
            ...state,
            [name]: value
        })
    }

    function onSubmit(e) {
        e.preventDefault();
        history.push(`/${branch}/${queue || 'default'}/${ticket ? `/${ticket}` : ''}`);
    }

    return (
        <section className="hero is-fullheight vq-intro-page" >
            <div className="hero-body">
                <div className="container has-text-centered">
                    <div className="columns">
                        <div className="column is-6 is-offset-3">
                            <figure className="image">
                                <img
                                    style={{ height: 85 }}
                                    src="VirtualQ.svg"
                                    alt="Logo"
                                />
                            </figure>
                        </div>
                    </div>
                    <form onSubmit={onSubmit}>
                        <div className="columns is-centered">
                            <div className="column is-2">
                                <input
                                    className="input register-input"
                                    name="branch"
                                    type="number"
                                    pattern="\d*"
                                    placeholder={t("location")}
                                    required
                                    value={branch}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="column is-2">
                                <input
                                    className="input register-input"
                                    name="queue"
                                    type="number"
                                    pattern="\d*"
                                    placeholder={t("queue")}
                                    value={queue}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="column is-2">
                                <input
                                    className="input register-input"
                                    name="ticket"
                                    type="number"
                                    pattern="\d*"
                                    placeholder={t("ticket")}
                                    value={ticket}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-6 is-offset-3">
                                <input
                                    type="submit"
                                    className="button is-large square-button"
                                    value={t("submit")}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}
