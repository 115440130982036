import React from 'react';
import { useTranslation } from 'react-i18next';

const CurrentTurn = ({
    calendar,
    currentTurn,
    location,
    queue,
    productColor
}) => {
  const { t } = useTranslation('display');
  return (
    <div
        className="card current-turn"
        style={{ background: productColor }}
    >
        <p className="text-host is-size-5">{location}</p>
        {queue && <p className="text-host is-size-5">{queue}</p>}
        <p className="text-current-turn is-size-4" style={{ lineHeight: '22px' }}>
            {[t('current-turn')]}
        </p>
        <div className="image-container">
            <figure className="image is-128x128">
                <img src={calendar} alt="calendar" />
                <p className="calendar-text">
                    <span className="text-current-turn-ticket">{currentTurn}</span>
                </p>
            </figure>
        </div>
    </div>
  )
};

export default CurrentTurn;
