import React from 'react';
import { useTranslation } from 'react-i18next';

export default function LanguageButton({ style }){
    const { t, i18n } = useTranslation('meta');
    const { language } = i18n;

    function onChange(event){
        const lng = event.target.value
        if (lng) {
            i18n.changeLanguage(lng);
            localStorage.setItem("language", lng);
        }
    }

    return (<div className="language-button control">
        <div className="select is-small">
            <select value={language} onChange={onChange}>{i18n.languages.map(lng =>
                <option value={lng}>{t('language', { lng })}</option>
            )}</select>
        </div>
    </div>);
}
