import React from 'react';
// import QRCode from '../../assets/qr-code.png';
import QRCode from 'qrcode.react';
import queryString from 'query-string';
import { LOCK_INSTITUTION } from '../../../constants';
import { useTranslation } from 'react-i18next';


const urlData = queryString.parse(window.location.hash)

function Thanks({
    config,
    host,
    branchId,
    name,
    reset,
    ticket
}){
    const { t } = useTranslation('register-thankyou');

    const {
        protocol, hostname, port
    } = window.location;

    const turn_href = `${protocol}//${hostname}${port ? `:${port}` : ''}/${branchId}${ticket ? `/${ticket}` : ''}${LOCK_INSTITUTION ? '' : `#host=${host}`}`;

    return (
      <div className="column is-8 is-offset-2">
        <div className="columns">
          <div className="column is-6 is-offset-3">
            <figure>
              <img
                style={{ width: '80%' }}
                src={config.theme.logo}
              />
            </figure>
          </div>
        </div>
        <div className="columns">
          <div className="column is-12">
            <h3 className="title text-instructions-title" style={{ padding: 5 }}>
              {t('thanks-1', { name })}
            </h3>
            <h3 className="subtitle text-instructions-subtitle" style={{ padding: 5 }}>
              {t('thanks-2')}
            </h3>
          </div>
        </div>

        <div className="columns is-centered">
          <div className="column is-5" style={{ textAlign: 'center' }}>
            <button
              className="button is-default square-button"
              onClick={() => reset()}
            >
              {t('ok')}
            </button>
          </div>
        </div>

        <div className="columns is-centered">
          {urlData.device ?
            (
              <div className="column is-5" style={{ textAlign: 'center' }}>
                <button
                  className="button is-default square-button"
                  onClick={() => { window.open(turn_href) }}
                >
                  {t('view-turn')}
                </button>
              </div>
            ):
            (
              <div className="column is-5" style={{ textAlign: 'center' }}>
                <h3 className="subtitle text-instructions-subtitle" style={{ padding: 5 }}>
                  {t('scan-qr')}
                </h3>
                <QRCode value={turn_href} />
              </div>
            )
          }
        </div>
      </div>
    );
}

export default Thanks;
