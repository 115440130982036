import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';


export default function Task({
    config,
    product,
    onUpdateState,
}) {
    const { t } = useTranslation('register-task');
    console.log("product", product);

    return (
        <div className="container has-text-centered">
            <div className="column">
                <h3 className="title text-instructions-title page-title">{t('title')}</h3>
            </div>
            <br/>
            <div className="columns">
                    <div className="column is-3" >
                        <div className="card borderless" style={{ backgroundColor: config.theme.productColor }}>
                            <div className="card-content">
                                <div className="content text-placeholder">
                                    <figure className="image is-2by2 product-image">
                                        <img src={product.icon} alt="Placeholder"/>
                                    </figure>
                                    {product.name}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-9">
                    {
                        Object.keys(product.tasks).map((key, i) => {
                            return (
                        <div className="columns is-12" key={i}>
                            <div className="column" style={{paddingBottom: 0}}>
                                <button className="button is-fullwidth task" style={{ color: config.theme.taskColor }}onClick={() => onUpdateState({task: product.tasks[key].id}, true)}>
                                    { key }
                                </button>
                            </div>
                        </div>)
                        })
                    }
                    </div>
                </div>
        </div>
    );
};
