import React from 'react';
import { useTranslation } from 'react-i18next';

const AproxMinutes = ({ avgTimeForPerson, avgWaitTime, ticket }) => {
    const { t } = useTranslation('display');
    return (
    <div className="columns is-mobile">
        <div className="column">
            <p className="text-wait-time has-text-centered">
                {t('waittime', {avgWaitTime})}
            </p>
        </div>
    </div>
    )
};

export default AproxMinutes;
